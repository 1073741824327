import React from 'react'

const About = () => {
    return (
        <div className="container sec1 flex">
            <div className="main">
            </div>
        </div>
    )
}

export default About;